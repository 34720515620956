import React, { useState } from "react";
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap';
////import 'bootstrap/dist/css/bootstrap-grid.min.css';;

import Layout from '../../components/layout';

import * as pricingStyles from './pricing.module.scss';

import heroImg from '../../images/pricing/pricing-hero@2x.png';
import deliveryImg from '../../images/pricing/reserve-packs.svg';


const PricingPage=({ location }) => {
    const showForm = (ev) => {
      ev.preventDefault();
      if (window.FreshworksWidget === undefined) {
        alert('Please enable performance cookies!');
      } else {
        window.FreshworksWidget('open');
      }
    }
    return(
        <>
            <Helmet>
                <title>BoomWriter UK: Simple &amp; Fair Pricing</title>
                <meta name="description" content="Our book packages are affordable and ensure every pupil receives a copy of the printed book." />
            </Helmet>
            <Layout location={location}>
                <div className={`${pricingStyles.pricingBg}`}>
                    <Container>
                        <section>
                            <Row className="flex-column flex-md-row pt-3 pt-md-6">
                                <Col className="text-center" xs={{}} md={{ span:"auto", order:1 }}><img className={`${pricingStyles.heroImg}`} src={ heroImg } alt="Free Delivery" /></Col>
                                <Col className="text-center text-md-left">
                                    <h1>Simple, Fair Pricing.</h1>
                                    <p>Our Book Packs contain a copy of the completed book for each pupil and one for the class.</p> 
                                    <p className="mb-2">The pack is delivered direct to your school ready for a class celebration.</p>
                                    <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="primary-btn mt-1">Purchase your packs</a>
                                </Col>
                            </Row>
                        </section>

                        <section className="price-section">
                            <Row className="flex-column flex-md-row pt-3 pt-md-6 m-0">
                                <Col>
                                    <Row className="mb-half flex-column flex-md-row">
                                        <Col className="light-bg mb-half mb-md-0 mr-md-half border-dark p-1">
                                            <Row className="pb-half">
                                                <Col>
                                                    <h3 className="mb-0">Small Class</h3>
                                                    <span className="secondary-light-highlight headingHero">£119.85</span>
                                                </Col>
                                                <Col xs="auto">

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className="border-top-dark pt-1">up to 15 students</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="light-bg border-dark p-1">
                                            <Row className="pb-half">
                                                <Col>
                                                    <h3 className="mb-0">Standard Class</h3>
                                                    <span className="secondary-light-highlight headingHero">£239.70</span>
                                                </Col>
                                                <Col xs="auto">

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className="border-top-dark pt-1">up to 30 students</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="flex-column flex-md-row">
                                        <Col className="dark-bg py-1 py-md-2 px-1 px-lg-2">
                                            <Row className="flex-column flex-md-row align-items-md-center reversed">
                                                <Col md="auto" className="mb-1 mb-md-0 mr-md-2 mr-lg-3">
                                                    <span className="heading">All Feature</span>
                                                </Col>
                                                <Col>
                                                    <ul className="headingSmall noStyle-list mb-0">
                                                        <li className="mb-half">Free &amp; Fast Delivery</li>
                                                        <li className="mb-half">Free Resources</li>
                                                        <li>1x Class Copy</li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md="auto" className="primary-bg py-1 py-md-2 px-1 px-lg-2 text-md-center d-flex align-items-center">
                                            <Row className="flex-md-column m-0 align-items-end align-items-md-center">
                                                <Col xs="auto" className="p-0">
                                                    <span className="headingSmall">Add Extra Copies&nbsp;</span>
                                                </Col>
                                                <Col xs="auto" className="p-0">
                                                    <span className="heading">£7.99 each</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="pt-3 pt-md-6">
                                <Col>
                                    <Row className="mb-2">
                                        <Col md={5} xl={4}>
                                            <h2>Full School Offers</h2>
                                            <p>Order your standard book packs for the academic year to receive a great discount.</p>
                                        </Col>
                                    </Row>
                                    <Row className="flex-column flex-md-row mb-2">
                                        <Col>
                                            <Row>
                                                <Col className="pr-0">
                                                    <h3 className="headingSmall mb-0">4 Standard Book Packs</h3>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-end pb-half">
                                                <Col className="pr-0">
                                                    <span className="heading fontWeight-bolder secondary-light-highlight">£838.95</span>
                                                </Col>
                                                <Col className="text-right" xs="auto">
                                                    <span>
                                                        save<br />
                                                        <span className="fontWeight-bold headingSmall">£119.85</span>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className="border-top-dark pt-half">Perfect for Single Form Entry Schools <br />50% off 1 class</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="my-2 my-md-0">
                                            <Row>
                                                <Col className="pr-0">
                                                    <h3 className="headingSmall mb-0">8 Standard Book Packs</h3>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-end pb-half">
                                                <Col className="pr-0">
                                                    <span className="heading fontWeight-bolder secondary-light-highlight">£1677.90</span>
                                                </Col>
                                                <Col className="text-right" xs="auto">
                                                    <span>
                                                        save<br />
                                                        <span className="fontWeight-bold headingSmall">£239.70</span>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="ml-lg-half">
                                                    <p className="border-top-dark pt-half">Perfect for Two Form Entry Schools <br />1 class free</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className="pr-0">
                                                    <h3 className="headingSmall mb-0">12 Standard Book Packs</h3>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-end pb-half">
                                                <Col className="pr-0">
                                                    <span className="heading fontWeight-bolder secondary-light-highlight">£2397</span>
                                                </Col>
                                                <Col className="text-right" xs="auto">
                                                    <span>
                                                        save<br />
                                                        <span className="fontWeight-bold headingSmall">£479.40</span>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className="border-top-dark pt-half">Perfect for Three Form Entry Schools <br />2 classes free</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h3>Larger Schools &amp; Academy Chains </h3>
                                            <p>We can provide a custom solution based around your needs.<br /><button onClick={ (ev) => showForm(ev) } className="inline-link">Contact us for an individual quotation.</button></p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </section>
                        
                        <section className="reservations">
                            <Row className="py-3 py-md-6 flex-column flex-md-row">
                                <Col className="mb-2 mb-md-0" xs={{}} md={{order:1}} lg={{span:5}}>
                                    <Row className="m-0 pl-lg-2">
                                        <Col className="border-dark p-2 light-bg">
                                            <h4>Parent Purchases</h4>
                                            <p>Parents can purchase additional copies of their child’s personalized book direct form our online store.</p>
                                            <span className="highlight heading fontWeight-bolder d-inline-block mr-half">£7.99/each </span><span className="headingSmall fontWeight-bold">+p&amp;p</span>
                                            <p>Orders are shipped to the home address.</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={{}} lg={{span:7}}>
                                    <Row className="mb-2">
                                        <Col xs="auto">
                                            <img className={`${pricingStyles.deliveryImg}`} src={ deliveryImg } alt="Free Delivery" />
                                        </Col>
                                        <Col className="text-center text-md-left">
                                            <h4 className="mb-half">Reserve Now for 2022/23</h4>
                                            <p className="mb-2">Lock in your books at this great price.</p>
                                            <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="primary-btn mt-1">Purchase your packs</a>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>Important Information</h5>
                                            <p>All Book Packs are valid for one academic year only and are non-refundable. You can use your packs at any time during the academic year. Please see our terms and conditions for full details. Your statutory rights are unaffected.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </section>

                    </Container>
                </div>
            </Layout>
        </>
    )
} 

export default PricingPage